import React from 'react';
import SliderStyleTwo from '../../../components/Elements/Slider/SliderStyleTwo';
import About from '../travel-agency/AboutSection';
import MainFeaturesSection from './MainFeaturesSection';
import TopFeaturedSection from './TopFeaturedSection';

import Pricing from '../travel-agency/PricingSection';

import Brand from '../../../components/Common/Brand'
import ScrollToTop from '../../../components/Common/ScrollTop';
import AppShowcase from '../travel-agency/AppShowcase';


const SalesoftTravelAgency = () => {
	return (
		<React.Fragment>
			{/* slider-start */}
			<SliderStyleTwo />
			{/* slider-start */}

			{/* FeaturedSection-area-start */}
			<TopFeaturedSection />
			{/* FeaturedSection-area-end */}

			{/* about-area-start */}
			<About />
			{/* about-area-end */}

             {/* brand-area-start */}
             {/* <Brand /> */}
			{/* brand-area-end */}

			{/* services-area-start */}
			<MainFeaturesSection />
			{/* services-area-end */}
			
			{/* process-area-start */}
			{/* <Process /> */}
			{/* process-area-end */}


			{/* WhyChooseUs-area-start */}
			<AppShowcase />
			{/* WhyChooseUs-area-end */}
			
			{/* price table start */}
			<Pricing />
			{/* price table end */}
			
			
			{/* testmonial-area-start */}
			{/* <Testimonial /> */}
			{/* testmonial-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default SalesoftTravelAgency;