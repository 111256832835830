import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlipTwo from '../../components/Service/SingleServiceFlipTwo';

// industry Icons
import img1 from '../../assets/img/industry/financial-services.png';
import img2 from '../../assets/img/industry/travel.png';
import img3 from '../../assets/img/industry/utilities.png';
import img4 from '../../assets/img/industry/manufacture.png';
import img5 from '../../assets/img/industry/retail.png';
import img6 from '../../assets/img/industry/logistics.png';
import img7 from '../../assets/img/industry/hotel-booking.png';
import img8 from '../../assets/img/industry/restaurant.png';
import img9 from '../../assets/img/industry/gym.png';

const IndustrySection = () => {

    return (
        <div id="rs-service" className="rs-services style2 modify2 pt-100 pb-120 md-pt-70 md-pb-54">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    subtitle="Project For Any Industry"
                    titleClass="title"
                    title="Best Solutions, For All Industries"
                />  
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipTwo
                            itemClass="flip-box-inner purple-bg"
                            serviceImage={img1}
                            Title="Financial Package"
                            TextDesc="We deliver Salesoft Package that manage Financial Industry to support your customer needs."
                            ButtonClass="readon view-more"
                            ButtonText="View More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipTwo
                            itemClass="flip-box-inner gold-bg"
                            serviceImage={img2}
                            Title="Travel Agency Package"
                            TextDesc="We deliver Salesoft Package that manage Travel Agency Industry to support your customer needs."
                            ButtonClass="readon view-more"
                            ButtonText="View More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipTwo
                            itemClass="flip-box-inner blue-bg"
                            serviceImage={img3}
                            Title="Utility Package"
                            TextDesc="We deliver Salesoft Package that manage Utilities Industry to support your customer needs."
                            ButtonClass="readon view-more"
                            ButtonText="View More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipTwo
                            itemClass="flip-box-inner purple-bg"
                            serviceImage={img9}
                            Title="GYM Package"
                            TextDesc="We deliver Salesoft Package that manage GYM Industry to support your customer needs."
                            ButtonClass="readon view-more"
                            ButtonText="View More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipTwo
                            itemClass="flip-box-inner gold-bg"
                            serviceImage={img5}
                            Title="Retail Package"
                            TextDesc="We deliver Salesoft Package that manage Retail Industry to support your customer needs."
                            ButtonClass="readon view-more"
                            ButtonText="View More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipTwo
                            itemClass="flip-box-inner gold-bg"
                            serviceImage={img7}
                            Title="Hotel Services"
                            TextDesc="We deliver Salesoft Package that manage Hotel Services to support your customer needs."
                            ButtonClass="readon view-more"
                            ButtonText="View More"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndustrySection;