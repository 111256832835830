import React from 'react';
import HeaderStyleTwo from '../../components/Layout/Header/HeaderStyleTwo';
import Footer from '../../components/Layout/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';

import IndexMain from './IndexMain';

const Index = () => {
	return (
		<React.Fragment>
			<OffWrap />
			<HeaderStyleTwo
				parentMenu='home'
				activeMenu='/'
                offCanvas='enable'
			/>
			<IndexMain />
			<Footer footerClass="rs-footer" />
		</React.Fragment>
	);
}

export default Index;
