import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

const SliderStyleTwo = () =>  {
    const navPrevIcon = () => ("<i class='fa fa-angle-left'></i>");
    const navNextIcon = () => ("<i class='fa fa-angle-right'></i>"); 

    const options = {
        items: 1,
        nav: true,
        dots: false,
        margin: 0,
        rewind: false,
        autoplay: false,
        stagePadding: 0,
        loop: true,
        center: false,
        navText: [navPrevIcon,navNextIcon],
        responsive: {
            0: {
                nav: false,
            },
            768: {
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
            <div className="rs-slider style1">
            <OwlCarousel options={options} >
                <div className="slider-content slide1">
                    <div className="container">
                        <div className="content-part">
                            <div className="sl-sub-title">Take Control of Your</div>
                            <h1 className="sl-title mb-mb-10">Entire Business</h1>
                            <div className="sl-desc">
                            Using modern technogoly & modern frameworks.
                            </div>
                            <div className="sl-btn">
                                <Link className="readon learn-more slider-btn" to="/contact">Get in touch</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider-content slide2">
                    <div className="container">
                        <div className="content-part">
                            <div className="sl-sub-title">We Increase Your</div>
                            <h1 className="sl-title mb-mb-10">Business Success</h1>
                            <div className="sl-desc">
                                Using advanced technogoly & modern frameworks
                            </div>
                            <div className="sl-btn">
                                <Link className="readon learn-more slider-btn" to="/contact">Get in touch</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
            </div>
        </React.Fragment>
    );
}

export default SliderStyleTwo;