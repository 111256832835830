import SectionTitle from '../../../components/Common/SectionTitle';
import SingleServiceFive from '../../../components/Service/SingleServiceFive';

// Service Icons
import imgMain1 from '../../../assets/img/service/style3/main-img/icon-c-1.png';
import imgHover1 from '../../../assets/img/service/style3/hover-img/icon-bw-1.png';

import imgMain2 from '../../../assets/img/service/style3/main-img/icon-c-2.png';
import imgHover2 from '../../../assets/img/service/style3/hover-img/icon-bw-2.png';

import imgMain3 from '../../../assets/img/service/style3/main-img/icon-c-3.png';
import imgHover3 from '../../../assets/img/service/style3/hover-img/icon-bw-3.png';

import imgMain4 from '../../../assets/img/service/style3/main-img/icon-c-4.png';
import imgHover4 from '../../../assets/img/service/style3/hover-img/icon-bw-4.png';

import imgMain5 from '../../../assets/img/service/style3/main-img/icon-c-5.png';
import imgHover5 from '../../../assets/img/service/style3/hover-img/icon-bw-5.png';

import imgMain6 from '../../../assets/img/service/style3/main-img/icon-c-6.png';
import imgHover6 from '../../../assets/img/service/style3/hover-img/icon-bw-6.png';

const Service = () => {

    return (
        <div className="rs-services style3 modify1 pt-120 pb-100 md-pt-70 md-pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    subtitle="Project For Any Industry"
                    titleClass="title"
                    title="Best Features, For All Industries"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item secondary-bg"
                            MainImg={imgMain1}
                            HoverImg={imgHover1}
                            Title="Visual Interface"
                            Text="Build a seamless, awesome, and powerful UI with different functionalities for optimum results."
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item primary-bg"
                            MainImg={imgMain2}
                            HoverImg={imgHover2}
                            Title="Performance Growth"
                            Text="By implementing latest and best coding standards, we will develop a responsive, fast and secure website."
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item secondary-bg"
                            MainImg={imgMain3}
                            HoverImg={imgHover3}
                            Title="Latest Technology"
                            Text="Most advanced technologies are used in our products and has always been updated with the latest trends."
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item primary-bg"
                            MainImg={imgMain4}
                            HoverImg={imgHover4}
                            Title="Onclick Invoice"
                            Text="Build professional and great looking invoices. Attach files and send directly to your clients including the invoice PDF."
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item secondary-bg"
                            MainImg={imgMain5}
                            HoverImg={imgHover5}
                            Title="Modern Accounting"
                            Text="Decision making processes is the foundation to everyone who follows strict professional tech standards in reporting and guidelines."
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceFive
                            itemClass="services-item primary-bg"
                            MainImg={imgMain6}
                            HoverImg={imgHover6}
                            Title="Customer Managemnt"
                            Text="Customer satisfaction is the heart of our hard work. We do whatever it takes to make them happy."
                            serviceBtn="Read More"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;