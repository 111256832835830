import React from 'react';
import { FaTiktok, FaYoutube, FaTwitter,FaFacebookF, FaInstagram} from 'react-icons/fa';

const TopHeaderStyleTwo = () => {

    return (
        <div className="topbar-area style2 hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <ul className="topbar-contact">
                            <li>
                                <i className="fa fa-envelope-open"></i>
                                <a href="mailto:info@salesoft.com">info@salesoft.com</a>
                            </li>
                            <li>
                                <i className="fa fa-phone"></i>
                                <a href="tel:+252614697777">+252 614 697777</a>
                            </li>
                            <li>
                                <i className="fa fa-map-marker"></i>
                                <a href="https://www.google.com/maps/place/Saafisoft" target="_blank">Mogadishu, Somalia.</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 text-right">
                        <div className="toolbar-sl-share">
                            <ul>
                                <li className="opening"> <em>
                                    <i className="far fa-clock"></i> 08:00AM - 08:00PM</em>
                                </li>
                                <li>
                                    <a href="https://facebook.com/Saafisoft"><i className="far fa-clock"><FaFacebookF/></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/Saafisoft"><i className="far fa-clock"><FaTwitter/></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCPsUPSde3YxZ9Qpfwb9u16g/"><i className="far fa-clock"><FaYoutube /></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/saafisoft/"><i className="far fa-clock"><FaInstagram /></i></a>
                                </li>
                                <li>
                                    <a href="https://www.tiktok.com/@saafisoft"><i className="far fa-clock"><FaTiktok /></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeaderStyleTwo;