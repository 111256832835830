import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';
import ScrollToTop from '../../components/Common/ScrollTop';

import bannerbg from '../../assets/img/breadcrumbs/2123258.jpg';


const ContactMain = () => {

        return (
            <React.Fragment>

                {/* SiteBreadcrumb */}
                <SiteBreadcrumb
                    pageTitle="Contact"
                    pageName="Contact"
                    breadcrumbsImg={bannerbg}
                />
                {/* SiteBreadcrumb */}

                {/* Contact Section Start */}
                <div className="rs-contact pt-120 md-pt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 md-mb-60">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-8 pl-70 md-pl-14">
                                <div className="contact-widget">
                                    <SectionTitle
                                        sectionClass="sec-title2 mb-40"
                                        subtitleClass="sub-text contact mb-14"
                                        subtitle="Get In Touch"
                                        titleClass="title testi-title"
                                        title="Fill The Form Below"
                                    />

                                    {/* Contact Form */}
                                    <ContactForm />
                                    {/* Contact Form */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="map-canvas pt-120 md-pt-80">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d498.41352549929496!2d45.313538!3d2.032501!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3d5841960052c635%3A0xc6de941eae5f1f75!2sSaafisoft!5e0!3m2!1sen!2sso!4v1676823816944!5m2!1sen!2sso" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
                {/* Contact Section End */}
            </React.Fragment>

        );
}


export default ContactMain;