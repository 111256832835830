import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/about/about-9.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 md-mb-30">
                        <div className="rs-animation-shape">
                            <div className="images dance">
                                <img src={aboutImg} alt="aboutImg" />
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="About Us"
                                    titleClass="title pb-36"
                                    title="We Are Increasing Business Success With Technology"
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription="Salesoft is a collection of tools designed for different industries, like financial services, Real estate, manufacturing,                                     logistics, retail, utilities and travel. With its clean and modern design,
                                      Salesoft can help you look more professional to your customers and help improve business performance at the same time with a powerful
                                       support system that helps you track and resolve issues quickly via the integrated ticket system."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/contact">Learn-More</Link>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default About;