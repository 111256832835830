import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';



//Custom Components
import Index from '../pages/index';

import About from '../pages/about';

import Insights from '../pages/insights';
import InsightDetails from '../pages/insights/insight-details';

import TravelAgency from '../pages/product/travel-agency';

import Pricing from '../pages/pricing';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    return (
        <div className='App'>
            <Router>  
                <LoadTop />          
                <Switch>
                    <Route path="/" exact component={Index} />
                   
                    <Route path="/about" component={About} />
                    
                    <Route path="/product/travel-agency" component={TravelAgency} />
         
                    <Route path="/insights" exact component={Insights} />
                    <Route path="/insight/insight-details" component={InsightDetails} />                    
                    
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                </Switch>
            </Router>
            
        </div>
    );
}

export default App;
