import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesWidget = () => {
    return (
        <div className="categories mb-50">
            <div className="widget-title">
                <h3 className="title">Categories</h3>
            </div>
            <ul>
                <li><Link to="#">Financial Services</Link></li>
                <li><Link to="#">Utilities</Link></li>
                <li><Link to="#">Media</Link></li>
                <li><Link to="#">Sports</Link></li>
                <li><Link to="#">Travel</Link></li>
                <li><Link to="#">Healthcare</Link></li>
                <li><Link to="#">Retail</Link></li>
                <li><Link to="#">Manufacturing</Link></li>
                <li><Link to="#">Organizations</Link></li>
                <li><Link to="#">Logistics</Link></li>
                <li><Link to="#">Institutions</Link></li>
                <li><Link to="#">Real Estate</Link></li>
            </ul>
        </div>
    )
}

export default CategoriesWidget;