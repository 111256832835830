import React from 'react';
import SingleService from '../../components/Service/SingleService';

import serviceIcon1 from '../../assets/img/features/1.png';
import serviceIcon2 from '../../assets/img/features/2.png';
import serviceIcon3 from '../../assets/img/features/3.png';
import serviceIcon4 from '../../assets/img/features/4.png';

const FeaturedSection = () => {

    return (
        <div className="rs-services main-home style1 pt-100 pb-100 md-pt-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 md-mb-50">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Full Optimization" 
                            // Title="Well Documented" 
                            Text="We described every module of our products which makes our customer's life easy." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 md-mb-50">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            Title="Data Security" 
                            Text="There is no compromise with our customer's security, even a single bit of data." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6  md-mb-50">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Optimized Reports" 
                            Text="Generate real-time reports at anytime and have clear view of everything." 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            Title="Customer First" 
                            Text="Great support system, and we are committed to answer at anytime any where." 
                        />
                    </div>                        
                </div>
            </div>
        </div>
    );
}

export default FeaturedSection;