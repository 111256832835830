import React from 'react';
import Blog from './BlogSection';
import About from './AboutSection';
import Industry from './IndustrySection';
import ProcessSection from './ProcessSection';
import Pricing from './PricingSection';
import VideoStyleOne from './VideoStyleOne';
import ScrollToTop from '../../components/Common/ScrollTop';

import SliderStyleTwo from '../../components/Elements/Slider/SliderStyleTwo';
import TopFeaturedSection from './TopFeaturedSection';
import MainFeaturesSection from './MainFeaturesSection';
import Technology from './TechnologySection';
import Counter from './CounterSection';
import CTA from './CtaSection'

const HomeMain = () => {
	return (
		<React.Fragment>
			{/* SliderStyleTwo-start */}
			{/* <SliderStyleTwo /> */}
			<SliderStyleTwo />
			{/* SliderStyleTwo-end */}
			
			{/* ServiceTwo-area-start */}
			<TopFeaturedSection />
			{/* ServiceTwo-area-end */}

			{/* about-area-start */}
			<About />
			{/* about-area-end */}
			
			{/* services-area-start */}
			<MainFeaturesSection />
			{/* services-area-end */}
			
			{/* Technology-start */}
			<Technology />
			{/* Technology-end */}

			{/* services-area-start */}
			<Industry />
			{/* services-area-end */}
			
			{/* process-area-start */}
			<ProcessSection />
			{/* process-area-end */}

			{/* Pricing-area-start */}
			<Pricing />
			{/* Pricing-area-end */}
			
			{/* CTA-area-start */}
			<CTA />
			{/* CTA-area-end */}
			
			{/* Blog-area-start */}
			<Blog />
			{/* Blog-area-end */}
			
			{/* Counter-start */}
			<Counter />
			{/* Counter-end */}
			
			{/* Counter-start */}
			<VideoStyleOne />
			{/* VideoStyleOne-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeMain;