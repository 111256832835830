import React from 'react';
import SectionTitle from '../../../components/Common/SectionTitle';
import SinglePricing from '../../../components/Pricing/SinglePricing';

// Pricing Image
import primaryImg from '../../../assets/img/pricing/1.png';
import secondaryImg from '../../../assets/img/pricing/3.png';


const Pricing = () => {
    
    return (
        <div className="rs-pricing white-bg pt-100 pb-144 md-pt-222 md-pb-54">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text"
                    subtitle="Pricing"
                    titleClass="title testi-title"
                    title="Our Pricing Plan"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 md-mb-74">
                        <SinglePricing
                            itemClass="pricing-table secondary-bg"
                            badge="40$"
                            pricingImage={primaryImg}
                            sysName="SILVER Package"
                            buttonClass="readon buy-now"
                            buttonText="Buy Now"
                            buttonURL="#"

                            // Feature List (1 to 4 item)
                            featureText1="Powerful Admin Panel"
                            ability1="yes"

                            featureText2="1 Native App"
                            ability2="no"

                            featureText3="Continues Updates"
                            ability3="yes"

                            featureText4="Support via E-mail and Phone"
                            ability4="no"
                        />
                    </div>

                    <div className="col-lg-4 md-mb-74">
                        <SinglePricing
                            itemClass="pricing-table primary-bg"
                            badge="60$"
                            pricingImage={secondaryImg}
                            // currency="$"
                            // price="64.99"
                            sysName="GOLD Package"
                            buttonClass="readon buy-now"
                            buttonText="Buy Now"
                            buttonURL="#"

                            // Feature List (1 to 4 item)
                            featureText1="Powerful Admin Panel"
                            ability1="yes"

                            featureText2="1 Native App"
                            ability2="yes"

                            featureText3="Continues Updates"
                            ability3="yes"

                            featureText4="Support via E-mail and Phone"
                            ability4="no"
                        />
                    </div>

                    <div className="col-lg-4 md-mb-74">
                        <SinglePricing
                            itemClass="pricing-table secondary-bg"
                            badge="80$"
                            pricingImage={primaryImg}
                            sysName="PLATINUM Package"
                            buttonClass="readon buy-now"
                            buttonText="Buy Now"
                            buttonURL="#"

                            // Feature List (1 to 4 item)
                            featureText1="Powerful Admin Panel"
                            ability1="yes"

                            featureText2="1 Native App"
                            ability2="yes"

                            featureText3="Continues Updates"
                            ability3="yes"

                            featureText4="Support via E-mail, Phone and Zoom"
                            ability4="yes"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Pricing;