import React from 'react';
import HeaderStyleTwo from '../../../components/Layout/Header/HeaderStyleTwo';
import TravelAgency from './TravelAgencyMain';
import Footer from '../../../components/Layout/Footer';
import OffWrap from '../../../components/Layout/Header/OffWrap';

const Product = () => {

    return (
        <React.Fragment>
            <OffWrap />
            <HeaderStyleTwo
                parentMenu='products'
                secondParentMenu='products'
                activeMenu='/travel-agency'
                offCanvas='enable'
            />
            <TravelAgency />
            <Footer footerClass="rs-footer" />
        </React.Fragment>
    );
}


export default Product;
