import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import canvasLogo from '../../../assets/img/logo/logo.png';
import { FaTiktok, FaMapPin, FaEnvelopeOpen } from 'react-icons/fa';

const CanvasMenu = () => {

    const canvasMenuRemove = () => {
        document.body.classList.remove('nav-expanded');
    };

    return (
        <React.Fragment>
            <nav className="right_menu_togle hidden-md">
                <div className="close-btn">
                    <div className="nav-link">
                        <a onClick={canvasMenuRemove} id="nav-close" className="humburger nav-expander" href="/#">
                            <span className="dot1"></span>
                            <span className="dot2"></span>
                            <span className="dot3"></span>
                            <span className="dot4"></span>
                            <span className="dot5"></span>
                            <span className="dot6"></span>
                            <span className="dot7"></span>
                            <span className="dot8"></span>
                            <span className="dot9"></span>
                        </a>
                    </div>
                </div>
                <div className="canvas-logo">
                    <Link to="/"><img src={canvasLogo} alt="logo" /></Link>
                </div>
                <div className="offcanvas-text">
                    <p>We keep ourselves updated on the latest trends and developments in any Industry to not only develop solutions but also consult our clients and help them be future-ready.</p>
                </div>
                <div className="canvas-contact">
                    <div className="address-area">
                        <div className="address-list">
                            <div className="info-icon">
                                <i className=""><FaMapPin /></i>
                            </div>
                            <div className="info-content">
                                <h4 className="title">Address</h4>
                                <em>TCC Apt, Opposite Tre-Piano Bldg. Makkah Almukaramah St, Hodan Dist. Mogadishu, Somalia.</em>
                            </div>
                        </div>
                        <div className="address-list">
                            <div className="info-icon">
                                <i className="fa fa-email"><FaEnvelopeOpen /></i>
                            </div>
                            <div className="info-content">
                                <h4 className="title">Email</h4>
                                <em>info@salesoft.com</em>
                            </div>
                        </div>
                        <div className="address-list">
                            <div className="info-icon">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div className="info-content">
                                <h4 className="title">Phone</h4>
                                <em href="tel:+252614697777">+252614697777</em>
                            </div>
                        </div>
                    </div>
                    <ul className="social">
                        <li>
                            <a href="https://facebook.com/Saafisoft" target="_blank"><i className="fa fa-facebook-f"></i></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/Saafisoft" target="_blank"><i className="fa fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCPsUPSde3YxZ9Qpfwb9u16g/" target="_blank"><i className="fa fa-youtube"></i></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/saafisoft/" target="_blank"><i className="fa fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@saafisoft" target="_blank"><i className="fa fa-tiktok"><FaTiktok /></i></a>
                        </li>
                    </ul>
                </div>
                <div className="btn-part mt-35">
                    <Link className="readon learn-more submit secondaryColor" to={{ pathname: "https://admin.saafisoft.so/login" }} target="_blank" >Get Start</Link>
                </div>
            </nav>
        </React.Fragment>
    );
}

export default CanvasMenu;