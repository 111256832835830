import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';
import { FaTiktok} from 'react-icons/fa';

import Newsletter from '../../Common/Newsletter';

import footerLogo1 from '../../../assets/img/logo/logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p>Salesoft can help you to look more professional to your customers and improve business performance.</p>
                            </div>
                            <ul className="footer-social md-mb-30">
                            <li>
                            <a href="https://facebook.com/Saafisoft" target="_blank"><i className="fa fa-facebook-f"></i></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/Saafisoft" target="_blank"><i className="fa fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCPsUPSde3YxZ9Qpfwb9u16g/" target="_blank"><i className="fa fa-youtube"></i></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/saafisoft/" target="_blank"><i className="fa fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@saafisoft" target="_blank"><i className="fa fa-tiktok"><FaTiktok /></i></a>
                        </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">Quick Links</h3>
                            <ul className="site-map">
                                <li><Link to="/product/travel-agency">Travel Agency</Link></li>
                                <li><Link to="/insights">Insights</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contact Info</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">TCC Apt, Opposite Tre-Piano Bldg. Makkah Almukaramah St, Hodan Dist. Mogadishu, Somalia.</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <Link to="tel:+252614697777">+252 614 697777</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <Link to="mailto:info@salesoft.com">info@salesoft.com</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        Opening Hours: 08:00AM - 08:00PM
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <h3 className="widget-title">Contact Us</h3>
                            <p className="widget-desc">Feel free to contact us anytime in order to consult your revolutionary ideas with us!.</p>
                            <p>
                                {/* newsletter-area-start */}
                                <Newsletter />
                                {/* newsletter-area-end */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;