import React from 'react';
import Header from '../../components/Layout/Header';
import InsightDetailsMain from '../../components/InsightDetails';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ScrollToTop from '../../components/Common/ScrollTop';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/4.jpg';

const BlogDetails = () => {
    return (
        <React.Fragment>

            <Header
                parentMenu='blog'
                activeMenu='/blog-details'
            />

            <SiteBreadcrumb
                pageTitle="Salesoft - New Feature"
                titleClass="page-title new-title pb-10"
                pageCategory="Salesoft Insights"
                pageName="Travel Agency Added New Feature"
                breadcrumbsImg={bannerbg}
            />

            {/* Blog Main */}
            <InsightDetailsMain />
            {/* Blog Main End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <Footer />

            <SearchModal />
        </React.Fragment>
    );
}


export default BlogDetails;

