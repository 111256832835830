import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../../components/Common/SectionTitle';

import aboutImg from '../../../assets/img/about/about-9.png'

const About = (props) => {
    return (
        <div id="rs-about" className="rs-about bg17 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                        <div className="images dance">
                            <img src={aboutImg} alt="something" />
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="About Us"
                            titleClass="title pb-38"
                            title="We Are Increasing Business Success With Technology"
                            secondDescClass="margin-0 pb-16"
                            secondDescription="We build cutting-edge technology to help travel and tourism businesses automate the management of customer communication, guest management, online booking, customer communication,
                              and logistics management. We have a dedicated team specializing in the design and develop end-to-end travel tech solutions by integrating OTAs’ &
                               tour operators’ websites. We keep ourselves updated on the latest trends and developments in travel and hospitality to not only develop solutions
                                but also consult our clients and help them be future-ready.
                              This adds immense value to our as well as our client’s businesses."
                        />
                        {/* Section Title End */}
                        <div className="btn-part">
                            <Link className="readon learn-more contact-us" to="/about">Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;