import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
                <Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>Home</Link>
            </li>
            <li className={parentMenu === 'products' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link href="#" >Products</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/product/travel-agency" className={activeMenu === "/travel-agency" ? "active-menu" : ""}>Travel Agency</Link>
                    </li>
                    <li>
                        <Link to="/product/retail" className={activeMenu === "/retail" ? "active-menu" : ""}>Retail</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'insights' ? 'current-menu-item' : ''}>
                <Link to="/insights" className={activeMenu === "/insights" ? "active-menu" : ""}>Insights</Link>
            </li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
            </li>
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;