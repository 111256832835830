import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleProcess from '../../components/Process/SingleProcess';
import VideoStyleOne from '../../components/Video/VideoStyleOne';
import CounterStyleTwo from '../../components/Elements/Counter/CounterStyleTwo';


// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage2 from '../../assets/img/process/2.png';
import processImage3 from '../../assets/img/process/3.png';
import processImage4 from '../../assets/img/process/4.png';


import aboutImg from '../../assets/img/about/about-8.png'
import danceEffectImg from '../../assets/img/about/effect-1.png';
import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';

import SectionTitle2 from '../../components/Common/SectionTitle';


// Service Icons
import imgMain1 from '../../assets/img/service/style3/main-img/icon-c-1.png';
import imgHover1 from '../../assets/img/service/style3/hover-img/icon-bw-1.png';

import imgMain2 from '../../assets/img/service/style3/main-img/icon-c-2.png';
import imgHover2 from '../../assets/img/service/style3/hover-img/icon-bw-2.png';

import imgMain3 from '../../assets/img/service/style3/main-img/icon-c-3.png';
import imgHover3 from '../../assets/img/service/style3/hover-img/icon-bw-3.png';

import imgMain4 from '../../assets/img/service/style3/main-img/icon-c-4.png';
import imgHover4 from '../../assets/img/service/style3/hover-img/icon-bw-4.png';

import imgMain5 from '../../assets/img/service/style3/main-img/icon-c-5.png';
import imgHover5 from '../../assets/img/service/style3/hover-img/icon-bw-5.png';

import imgMain6 from '../../assets/img/service/style3/main-img/icon-c-6.png';
import imgHover6 from '../../assets/img/service/style3/hover-img/icon-bw-6.png';


const AboutMain = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="rs-about" className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 md-mb-30">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={aboutImg} alt="something" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="something" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="About Us"
                                    titleClass="title pb-36"
                                    title="We Are Increasing Business Success With Technology"
                                    descClass="desc pb-34"
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription="Salesoft is a collection of tools designed for different industries, like financial services. Real estate, manufacturing, logistics,
                                     retail, utilities and travel. With its clean and modern design, Salesoft can help you look more professional to your customers
                                     and help improve business performance at the same time with a powerful support system that helps you track and resolve issues quickly via the integrated ticket system."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/contact">Learn-More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="something" />
                        <img className="bottom dance" src={effectImg2} alt="something" />
                    </div>
                </div>
            </div>
            
            {/* Best Features-area-end */}
            <div className="rs-services style3 modify1 pt-120 pb-100 md-pt-70 md-pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Project For Any Industry"
                        titleClass="title"
                        title="Best Features, For All Industries"
                    />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleServiceFive
                                itemClass="services-item secondary-bg"
                                MainImg={imgMain1}
                                HoverImg={imgHover1}
                                Title="Visual Interface"
                                Text="Build a seamless, awesome, and powerful UI with different functionalities for optimum results."
                                serviceBtn="Read More"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleServiceFive
                                itemClass="services-item primary-bg"
                                MainImg={imgMain2}
                                HoverImg={imgHover2}
                                Title="Performance Growth"
                                Text="By implementing latest and best coding standards, we will develop a responsive, fast and secure website."
                                serviceBtn="Read More"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleServiceFive
                                itemClass="services-item secondary-bg"
                                MainImg={imgMain3}
                                HoverImg={imgHover3}
                                Title="Latest Technology"
                                Text="Most advanced technologies are used in our products and has always been updated with the latest trends."
                                serviceBtn="Read More"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleServiceFive
                                itemClass="services-item primary-bg"
                                MainImg={imgMain4}
                                HoverImg={imgHover4}
                                Title="Onclick Invoice"
                                Text="Build professional and great looking invoices. Attach files and send directly to your clients including the invoice PDF."
                                serviceBtn="Read More"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleServiceFive
                                itemClass="services-item secondary-bg"
                                MainImg={imgMain5}
                                HoverImg={imgHover5}
                                Title="Modern Accounting"
                                Text="Decision making processes is the foundation to everyone who follows strict professional tech standards in reporting and guidelines."
                                serviceBtn="Read More"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleServiceFive
                                itemClass="services-item primary-bg"
                                MainImg={imgMain6}
                                HoverImg={imgHover6}
                                Title="Customer Managemnt"
                                Text="Customer satisfaction is the heart of our hard work. We do whatever it takes to make them happy."
                                serviceBtn="Read More"
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            {/* working-process-area-start */}
            <div className="rs-process style2 gray-bg pt-120 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title2"
                        title="Our Working Process"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discovery"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage2}
                                titleClass="title"
                                Title="Planning"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage3}
                                titleClass="title"
                                Title="Execute"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage4}
                                titleClass="title"
                                Title="Deliver"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}

            {/* counter area start */}
            <CounterStyleTwo />
            {/* counter area end */}

            {/* VideoStyleThree area start */}
            <VideoStyleOne />
            {/* VideoStyleThree area end */}

        </React.Fragment>
    )
}

export default AboutMain;